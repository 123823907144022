var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"log-row d-flex pr-12 justify-start align-center",class:{
    'log-row-color-1': !_vm.isQueriedLog() && _vm.index % 2 === 0,
    'log-row-color-2': !_vm.isQueriedLog() && _vm.index % 2 !== 0,
    'log-row-color-queried': _vm.isQueriedLog()
  },attrs:{"tabindex":"0"}},[_c('div',{staticClass:"log-actions"},[_c('div',{staticClass:"log-link"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"blue-grey lighten-1","text":"","x-small":""},on:{"click":_vm.copyLogLink}},on),[_c('transition',{attrs:{"name":"log-copy","mode":"out-in"}},[(_vm.linkCopied)?_c('v-icon',{key:"copied"},[_vm._v("check")]):_c('v-icon',{key:"not-copied"},[_vm._v("link")])],1)],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.linkCopied ? 'Copied!' : 'Copy log URL to clipboard')+" ")])])],1)]),_c('div',{staticClass:"log-info text-caption py-2 text-left"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('div',_vm._g({staticClass:"log-datetime utilGrayMid--text"},on),[_vm._v(" "+_vm._s(_vm.logTime(_vm.log.timestamp))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.logDate(_vm.log.timestamp)))])]),_c('div',{staticClass:"log-level d-flex align-center justify-start"},[_c('v-icon',{attrs:{"color":_vm.logLevelColor(_vm.log.level),"x-small":""}},[_vm._v("lens")]),_c('span',{staticClass:"text-truncate ml-1 text-small-caps"},[_vm._v(_vm._s(_vm.log.level))])],1),_c('div',{staticClass:"log-name text-caption font-weight-light"},[_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.source))])])],1),_c('div',{staticClass:"ml-10 py-1"},[_c('span',{staticClass:"log-message"},[_vm._v(_vm._s(_vm.log.message))])]),(_vm.log.task_run_id)?_c('div',{staticClass:"justify-self-end log-router-link"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"log-router-button vertical-button",attrs:{"color":"blue-grey lighten-1","small":"","text":"","tile":"","to":{
            name: 'task-run',
            params: {
              id: _vm.log.task_run_id
            }
          }}},on),[_c('v-icon',[_vm._v("chevron_right")]),_vm._v(" To Task Run ")],1)]}}],null,false,1373949981)},[_c('span',[_vm._v(" Navigate to the task run associated with this log ")])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }