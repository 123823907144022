var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.discard),expression:"discard"}],staticClass:"inherited-text-field",class:{ focused: _vm.focused, 'cursor-pointer': !_vm.focused },on:{"click":_vm.edit}},[(!_vm.loading)?_c('v-icon',{staticClass:"prepend-input edit-icon",attrs:{"small":""}},[_vm._v("edit")]):_c('v-progress-circular',{staticClass:"prepend-input",attrs:{"indeterminate":"","color":"primary","size":"16","width":"2"}}),_c('v-menu',{attrs:{"value":_vm.focused && _vm.required && (!_vm.value || _vm.value.length === 0) && _vm.error,"close-on-click":false,"open-on-click":false,"close-on-content-click":false,"offset-y":"","max-width":"320","nudge-bottom":"5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-input',_vm._g({staticClass:"input-container",class:{
          'error-border': _vm.required && (!_vm.value || _vm.value.length === 0),
          disabled: _vm.loading
        },attrs:{"hide-details":"","error":_vm.required && (!_vm.value || _vm.value.length === 0)},on:{"disabled":_vm.loading}},on),[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],ref:"edit-input",staticClass:"text-truncate",class:{
            'cursor-pointer': !_vm.focused
          },attrs:{"placeholder":(_vm.label + " (press enter to save)")},domProps:{"value":(_vm.value)},on:{"disabled":_vm.loading,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.discard($event)}],"focus":function($event){_vm.focused = !_vm.loading && true},"input":function($event){if($event.target.composing){ return; }_vm.value=$event.target.value}}})])]}}])},[_c('v-alert',{staticClass:"ma-0",attrs:{"border":"left","colored-border":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.label)+" can't be empty ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }